.homePage {
    /* background-color: black; */
    background-image: linear-gradient(180deg, black, rgb(66, 66, 66));
    position: absolute;
    width: 100%;
    height: 600%;
}

.faceBabylon {
    width: 60%;
    height: 14.7%;
    top: 1.5%;
    left: 0%;
    position: relative;
    opacity: 0;
    z-index: 2;
    cursor: grab;
}

@media only screen and (max-width: 768px) {
    .faceBabylon {
        width: 50%;
        top: 3.5%;
    }
}

.faceBabylon:active {
    cursor: grabbing;
}

.faceBabylonCanvas, .picOrbitCanvas, .connect3DCanvas {
    margin: 0%;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    position: relative;
    outline: none;
    z-index: 1;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

section {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: center;
    transition-duration: .2s;
}

section .titleTextBox {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    background: linear-gradient(45deg, rgba(0,0,0,1) 10%, silver 60%);
    clip-path: circle(23% at 50% 50%);
}

section h2,
section .titleTextBox h2 {
    position: absolute;
    color: black;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 9vw;
    line-height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    top: calc(50% - 1em + 20px);
    left: 50%;
    opacity: 0;
}

section h2 {
    color: #fd5151;
}

.about-page {
    position: relative;
    top: 7%;
    height: 28%;
    width: 100%;
    /* background-color: navy; */
}

.bgPhrases {
    position: absolute;
    top:0%;
    left:0%;
    height: 100%;
    width: 100%;
}

.leftDesc {
    position: relative;
    top: 0%;
    left:-15em;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(48, 48, 48);
    font-size: calc(6vw + 6vh);
    display: inline-block;
    margin: 0%;
    padding: 0%;
    opacity: .2;
    white-space:nowrap;
}

.rightDesc {
    position: relative;
    top: 0%;
    left:110%;
    float: right;
    margin: 0%;
    padding: 0%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(48, 48, 48);
    font-size: calc(6vw + 6vh);
    opacity: .2;
    white-space:nowrap;
}

.aboutContent {
    position: absolute;
    z-index: 3;
    height:100%;
    width:100%;
    /* background-color: blanchedalmond; */
}

.intro {
    position: relative;
    top: 21vw;
    left:0%;
    height: 8vw;
    width: 100%;
}

.rectangle {
    position: absolute;
    height: 100%;
    top: 0%;
    left: 48%;
    width: 0;
    background-color: #fd5151;
}

.hiDiv {
    position: absolute;
    top: 0%;
    width: 100%;
    height:6vw;
    left: 0%;
    margin: 0%;
    padding: 0%;
    color: silver;
    z-index: 5;
}

.hi {
    position: relative;
    text-align: center;
    top: 0%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 6vw;
    margin: 0%;
    padding: 0%;
    color: white;
    z-index: 5;
}

.description{
    top: 23vw;
    position: relative;
    display:inline-flex;
    flex-wrap:wrap;
    width:100%;
    height:49%;
}
.flex-direction{
    flex-direction:row;
}
.picOrbit{
    width:50%;
    height:auto;
}
  
.aboutDesc{
    width:50%;
    color: silver;
    text-align: left;
    height:100%;
    font-size: calc(1vw + 1vh);
    font-family: 'Roboto', sans-serif;
    margin: 0%;
    padding: 2px 50px;
}

.red {
    color: #fd5151;
}
  
@media screen and (max-width: 768px) {
    .intro {
        top: 20vw;
    }

    .description{
        top: 24vw;
    }

    .flex-direction{
        flex-direction:column;
        justify-content: center;
    }
    .picOrbit{
        height:54.5%;
        width:80%;
    }
    .aboutDesc{
        width:100%;
        height:45.4%;
        text-align: center;
        padding: 20px;
        padding-top: 10px;
        font-size: calc(1vw + 1.2vh);
    }
}

.explore {
    position: relative;
    top: 10%;
    height: 25%;
    width: 100%;
    /* background-color: navy; */
}

.exploreTextDiv {
    position: relative;
    top: 12%;
    height: 10%;
    width: 80%;
    left: 10%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.exploreText {
    position: absolute;
    text-align: center;
    font-size: 4vw;
    margin: 0%;
    padding: 0%;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 5;
}

.bgExploreText {
    position: absolute;
    text-align: center;
    font-size: 4vw;
    margin: 0%;
    padding: 0%;
    color: #fd5151;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    z-index: 1;
}

.circle-container {
    position: relative;
    top: 14%;
    width: 100%;
    height: 85%;
}

.circle {
    position: relative;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    left: calc(50% - 37.5px);
    background-color: silver;
    margin-top: 75px;
    z-index: 7;
    cursor: default;
}

.circle.gold {
    background-color: #fdcf51;
}

.circle.purple {
    background-color: #845ad6;
}

.circle.green {
    background-color: #4afb7f;
}

.circle.blue {
    background-color: #51a0fd;
}

.circleImg {
    height: 75px;
    width: 75px;
    z-index: 8;
}

.circleText {
    color: white;
    position: absolute;
    font-size: calc(2vw + 8px);
    padding: 0%;
    margin: 0%;
    top: 0;
    left: calc(-4vw - 8px);
    transform: scale(0,0);
    z-index: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.contact {
    position: relative;
    /* background-color: #fd5151; */
    top: 8%;
    height: 24.5%;
    width: 100%;
}

.connect3D {
    position: absolute;
    height:90%;
    max-height: 90%;
    top:10%;
    width: 100%;
}

.connectTitle {
    position: absolute;
    top: 25.5%;
    left: 8%;
    color: white;
    font-size: 8vw;
    font-family: 'Raleway', sans-serif;
    margin: 0%;
    padding: 0%;
    text-decoration: underline;
    text-decoration-color: #fd5151;
    text-underline-offset: 10px;
}

.connectDesc {
    position: absolute;
    top: calc(24% + 9vw + 6vh);
    left: 8%;
    margin: 0%;
    padding: 0%;
    text-align: center;
    width: 32%;
    font-size: calc(1.5vw + 6px);
    /* background-color: #51a0fd; */
    font-family: 'Raleway', sans-serif;
    color: white;
}

@media only screen and (max-width: 768px) {
    .about-page {
        top: 5.5%;
    }

    .explore {
        top: 15%;
    }

    .exploreTextDiv {
        top: 5%;
    }
    
    .bgExploreText,
    .exploreText {
        font-size: 6vw;
    }

    .circle-container {
        position: relative;
        top: 2%;
        width: 100%;
        height: 85%;
    }

    .circleText {
        font-size: calc(4vw + 8px);
        left: calc(-16vw - 8px);
    }

    .contact {
        top: 15%;
        height: 20%;
        z-index: 8;
    }

    .connect3D {
        top: 0%;
        height: 90%;
        z-index: 9;
    }
    
    .connectTitle {
        position: absolute;
        top: 5%;
        left: 8%;
    }
    
    .connectDesc {
        position: absolute;
        top: calc(3% + 9vw + 5vh);
        left: 8%;
        margin: 0%;
        padding: 0%;
        text-align: center;
        width: 32%;
        font-size: calc(1.5vw + 6px);
    }

    .homePage {
        height: 475%;
    }
}