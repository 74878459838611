/* stars from: https://pastebin.com/gEfdRwgc */

@media (min-width: 1024px) {

    .stars {
        position: absolute;
        width: 1px;
        height: 1px;
        background: transparent;
        z-index: 0;
        animation: animStar 175s linear infinite;
        box-shadow: 779px 1331px #bbb, 324px 42px #bbb, 303px 586px #bbb,
        1312px 276px #bbb, 451px 625px #bbb, 521px 1931px #bbb, 1087px 1871px #bbb,
        36px 1546px #bbb, 132px 934px #bbb, 1698px 901px #bbb, 1418px 664px #bbb,
        1448px 1157px #bbb, 1084px 232px #bbb, 347px 1776px #bbb, 1722px 243px #bbb,
        1629px 835px #bbb, 479px 969px #bbb, 1231px 960px #bbb, 586px 384px #bbb,
        164px 527px #bbb, 8px 646px #bbb, 1150px 1126px #bbb, 665px 1357px #bbb,
        1556px 1982px #bbb, 1260px 1961px #bbb, 1675px 1741px #bbb,
        1843px 1514px #bbb, 718px 1628px #bbb, 242px 1343px #bbb, 1497px 1880px #bbb,
        1364px 230px #bbb, 1739px 1302px #bbb, 636px 959px #bbb, 304px 686px #bbb,
        614px 751px #bbb, 1935px 816px #bbb, 1428px 60px #bbb, 355px 335px #bbb,
        1594px 158px #bbb, 90px 60px #bbb, 1553px 162px #bbb, 1239px 1825px #bbb,
        1945px 587px #bbb, 749px 1785px #bbb, 1987px 1172px #bbb, 1301px 1237px #bbb,
        1039px 342px #bbb, 1585px 1481px #bbb, 995px 1048px #bbb, 524px 932px #bbb,
        214px 413px #bbb, 1701px 1300px #bbb, 1037px 1613px #bbb, 1871px 996px #bbb,
        1360px 1635px #bbb, 1110px 1313px #bbb, 412px 1783px #bbb, 1949px 177px #bbb,
        903px 1854px #bbb, 700px 1936px #bbb, 378px 125px #bbb, 308px 834px #bbb,
        1118px 962px #bbb, 1350px 1929px #bbb, 781px 1811px #bbb, 561px 137px #bbb,
        757px 1148px #bbb, 1670px 1979px #bbb, 343px 739px #bbb, 945px 795px #bbb,
        576px 1903px #bbb, 1078px 1436px #bbb, 1583px 450px #bbb, 1366px 474px #bbb,
        297px 1873px #bbb, 192px 162px #bbb, 1624px 1633px #bbb, 59px 453px #bbb,
        82px 1872px #bbb, 1933px 498px #bbb, 1966px 1974px #bbb, 1975px 1688px #bbb,
        779px 314px #bbb, 1858px 1543px #bbb, 73px 1507px #bbb, 1693px 975px #bbb,
        1683px 108px #bbb, 1768px 1654px #bbb, 654px 14px #bbb, 494px 171px #bbb,
        1689px 1895px #bbb, 1660px 263px #bbb, 1031px 903px #bbb, 1203px 1393px #bbb,
        1333px 1421px #bbb, 1113px 41px #bbb, 1206px 1645px #bbb, 1325px 1635px #bbb,
        142px 388px #bbb, 572px 215px #bbb, 1535px 296px #bbb, 1419px 407px #bbb,
        1379px 1003px #bbb, 329px 469px #bbb, 1791px 1652px #bbb, 935px 1802px #bbb,
        1330px 1820px #bbb, 421px 1933px #bbb, 828px 365px #bbb, 275px 316px #bbb,
        707px 960px #bbb, 1605px 1554px #bbb, 625px 58px #bbb, 717px 1697px #bbb,
        1669px 246px #bbb, 1925px 322px #bbb, 1154px 1803px #bbb, 1929px 295px #bbb,
        1248px 240px #bbb, 1045px 1755px #bbb, 166px 942px #bbb, 1888px 1773px #bbb,
        678px 1963px #bbb, 1370px 569px #bbb, 1974px 1400px #bbb, 1786px 460px #bbb,
        51px 307px #bbb, 784px 1400px #bbb, 730px 1258px #bbb, 1712px 393px #bbb,
        416px 170px #bbb, 1797px 1932px #bbb, 572px 219px #bbb, 1557px 1856px #bbb,
        218px 8px #bbb, 348px 1334px #bbb, 469px 413px #bbb, 385px 1738px #bbb,
        1357px 1818px #bbb, 240px 942px #bbb, 248px 1847px #bbb, 1535px 806px #bbb,
        236px 1514px #bbb, 1429px 1556px #bbb, 73px 1633px #bbb, 1398px 1121px #bbb,
        671px 1301px #bbb, 1404px 1663px #bbb, 740px 1018px #bbb, 1600px 377px #bbb,
        785px 514px #bbb, 112px 1084px #bbb, 1915px 1887px #bbb, 1463px 1848px #bbb,
        687px 1115px #bbb, 1268px 1768px #bbb, 1729px 1425px #bbb,
        1284px 1022px #bbb, 801px 974px #bbb, 1975px 1317px #bbb, 1354px 834px #bbb,
        1446px 1484px #bbb, 1283px 1786px #bbb, 11px 523px #bbb, 1842px 236px #bbb,
        1355px 654px #bbb, 429px 7px #bbb, 1033px 1128px #bbb, 157px 297px #bbb,
        545px 635px #bbb, 52px 1080px #bbb, 827px 1520px #bbb, 1121px 490px #bbb,
        9px 309px #bbb, 1744px 1586px #bbb, 1014px 417px #bbb, 1534px 524px #bbb,
        958px 552px #bbb, 1403px 1496px #bbb, 387px 703px #bbb, 1522px 548px #bbb,
        1355px 282px #bbb, 1532px 601px #bbb, 1838px 790px #bbb, 290px 259px #bbb,
        295px 598px #bbb, 1601px 539px #bbb, 1561px 1272px #bbb, 34px 1922px #bbb,
        1024px 543px #bbb, 467px 369px #bbb, 722px 333px #bbb, 1976px 1255px #bbb,
        766px 983px #bbb, 1582px 1285px #bbb, 12px 512px #bbb, 617px 1410px #bbb,
        682px 577px #bbb, 1334px 1438px #bbb, 439px 327px #bbb, 1617px 1661px #bbb,
        673px 129px #bbb, 794px 941px #bbb, 1386px 1902px #bbb, 37px 1353px #bbb,
        1467px 1353px #bbb, 416px 18px #bbb, 187px 344px #bbb, 200px 1898px #bbb,
        1491px 1619px #bbb, 811px 347px #bbb, 924px 1827px #bbb, 945px 217px #bbb,
        1735px 1228px #bbb, 379px 1890px #bbb, 79px 761px #bbb, 825px 1837px #bbb,
        1980px 1558px #bbb, 1308px 1573px #bbb, 1488px 1726px #bbb,
        382px 1208px #bbb, 522px 595px #bbb, 1277px 1898px #bbb, 354px 552px #bbb,
        161px 1784px #bbb, 614px 251px #bbb, 526px 1576px #bbb, 17px 212px #bbb,
        179px 996px #bbb, 467px 1208px #bbb, 1944px 1838px #bbb, 1140px 1093px #bbb,
        858px 1007px #bbb, 200px 1064px #bbb, 423px 1964px #bbb, 1945px 439px #bbb,
        1377px 689px #bbb, 1120px 1437px #bbb, 1876px 668px #bbb, 907px 1324px #bbb,
        343px 1976px #bbb, 1816px 1501px #bbb, 1849px 177px #bbb, 647px 91px #bbb,
        1984px 1012px #bbb, 1336px 1300px #bbb, 128px 648px #bbb, 305px 1060px #bbb,
        1324px 826px #bbb, 1263px 1314px #bbb, 1801px 629px #bbb, 1614px 1555px #bbb,
        1634px 90px #bbb, 1603px 452px #bbb, 891px 1984px #bbb, 1556px 1906px #bbb,
        121px 68px #bbb, 1676px 1714px #bbb, 516px 936px #bbb, 1947px 1492px #bbb,
        1455px 1519px #bbb, 45px 602px #bbb, 205px 1039px #bbb, 793px 172px #bbb,
        1562px 1739px #bbb, 1056px 110px #bbb, 1512px 379px #bbb, 1795px 1621px #bbb,
        1848px 607px #bbb, 262px 1719px #bbb, 477px 991px #bbb, 483px 883px #bbb,
        1239px 1197px #bbb, 1496px 647px #bbb, 1649px 25px #bbb, 1491px 1946px #bbb,
        119px 996px #bbb, 179px 1472px #bbb, 1341px 808px #bbb, 1565px 1700px #bbb,
        407px 1544px #bbb, 1754px 357px #bbb, 1288px 981px #bbb, 902px 1997px #bbb,
        1755px 1668px #bbb, 186px 877px #bbb, 1202px 1882px #bbb, 461px 1213px #bbb,
        1400px 748px #bbb, 1969px 1899px #bbb, 809px 522px #bbb, 514px 1219px #bbb,
        374px 275px #bbb, 938px 1973px #bbb, 357px 552px #bbb, 144px 1722px #bbb,
        1572px 912px #bbb, 402px 1858px #bbb, 1544px 1195px #bbb, 667px 1257px #bbb,
        727px 1496px #bbb, 993px 232px #bbb, 1772px 313px #bbb, 1040px 1590px #bbb,
        1204px 1973px #bbb, 1268px 79px #bbb, 1555px 1048px #bbb, 986px 1707px #bbb,
        978px 1710px #bbb, 713px 360px #bbb, 407px 863px #bbb, 461px 736px #bbb,
        284px 1608px #bbb, 103px 430px #bbb, 1283px 1319px #bbb, 977px 1186px #bbb,
        1966px 1516px #bbb, 1287px 1129px #bbb, 70px 1098px #bbb, 1189px 889px #bbb,
        1126px 1734px #bbb, 309px 1292px #bbb, 879px 764px #bbb, 65px 473px #bbb,
        1003px 1959px #bbb, 658px 791px #bbb, 402px 1576px #bbb, 35px 622px #bbb,
        529px 1589px #bbb, 164px 666px #bbb, 1876px 1290px #bbb, 1541px 526px #bbb,
        270px 1297px #bbb, 440px 865px #bbb, 1500px 802px #bbb, 182px 1754px #bbb,
        1264px 892px #bbb, 272px 1249px #bbb, 1289px 1535px #bbb, 190px 1646px #bbb,
        955px 242px #bbb, 1456px 1597px #bbb, 1727px 1983px #bbb, 635px 801px #bbb,
        226px 455px #bbb, 1396px 1710px #bbb, 849px 1863px #bbb, 237px 1264px #bbb,
        839px 140px #bbb, 1122px 735px #bbb, 1280px 15px #bbb, 1318px 242px #bbb,
        1819px 1148px #bbb, 333px 1392px #bbb, 1949px 553px #bbb, 1878px 1332px #bbb,
        467px 548px #bbb, 1812px 1082px #bbb, 1067px 193px #bbb, 243px 156px #bbb,
        483px 1616px #bbb, 1714px 933px #bbb, 759px 1800px #bbb, 1822px 995px #bbb,
        1877px 572px #bbb, 581px 1084px #bbb, 107px 732px #bbb, 642px 1837px #bbb,
        166px 1493px #bbb, 1555px 198px #bbb, 819px 307px #bbb, 947px 345px #bbb,
        827px 224px #bbb, 927px 1394px #bbb, 540px 467px #bbb, 1093px 405px #bbb,
        1140px 927px #bbb, 130px 529px #bbb, 33px 1980px #bbb, 1147px 1663px #bbb,
        1616px 1436px #bbb, 528px 710px #bbb, 798px 1100px #bbb, 505px 1480px #bbb,
        899px 641px #bbb, 1909px 1949px #bbb, 1311px 964px #bbb, 979px 1301px #bbb,
        1393px 969px #bbb, 1793px 1886px #bbb, 292px 357px #bbb, 1196px 1718px #bbb,
        1290px 1994px #bbb, 537px 1973px #bbb, 1181px 1674px #bbb,
        1740px 1566px #bbb, 1307px 265px #bbb, 922px 522px #bbb, 1892px 472px #bbb,
        384px 1746px #bbb, 392px 1098px #bbb, 647px 548px #bbb, 390px 1498px #bbb,
        1246px 138px #bbb, 730px 876px #bbb, 192px 1472px #bbb, 1790px 1789px #bbb,
        928px 311px #bbb, 1253px 1647px #bbb, 747px 1921px #bbb, 1561px 1025px #bbb,
        1533px 1292px #bbb, 1985px 195px #bbb, 728px 729px #bbb, 1712px 1936px #bbb,
        512px 1717px #bbb, 1528px 483px #bbb, 313px 1642px #bbb, 281px 1849px #bbb,
        1212px 799px #bbb, 435px 1191px #bbb, 1422px 611px #bbb, 1718px 1964px #bbb,
        411px 944px #bbb, 210px 636px #bbb, 1502px 1295px #bbb, 1434px 349px #bbb,
        769px 60px #bbb, 747px 1053px #bbb, 789px 504px #bbb, 1436px 1264px #bbb,
        1893px 1225px #bbb, 1394px 1788px #bbb, 1108px 1317px #bbb,
        1673px 1395px #bbb, 854px 1010px #bbb, 1705px 80px #bbb, 1858px 148px #bbb,
        1729px 344px #bbb, 1388px 664px #bbb, 895px 406px #bbb, 1479px 157px #bbb,
        1441px 1157px #bbb, 552px 1900px #bbb, 516px 364px #bbb, 1647px 189px #bbb,
        1427px 1071px #bbb, 785px 729px #bbb, 1080px 1710px #bbb, 504px 204px #bbb,
        1177px 1622px #bbb, 657px 34px #bbb, 1296px 1099px #bbb, 248px 180px #bbb,
        1212px 1568px #bbb, 667px 1562px #bbb, 695px 841px #bbb, 1608px 1247px #bbb,
        751px 882px #bbb, 87px 167px #bbb, 607px 1368px #bbb, 1363px 1203px #bbb,
        1836px 317px #bbb, 1668px 1703px #bbb, 830px 1154px #bbb, 1721px 1398px #bbb,
        1601px 1280px #bbb, 976px 874px #bbb, 1743px 254px #bbb, 1020px 1815px #bbb,
        1670px 1766px #bbb, 1890px 735px #bbb, 1379px 136px #bbb, 1864px 695px #bbb,
        206px 965px #bbb, 1404px 1932px #bbb, 1923px 1360px #bbb, 247px 682px #bbb,
        519px 1708px #bbb, 645px 750px #bbb, 1164px 1204px #bbb, 834px 323px #bbb,
        172px 1350px #bbb, 213px 972px #bbb, 1837px 190px #bbb, 285px 1806px #bbb,
        1047px 1299px #bbb, 1548px 825px #bbb, 1730px 324px #bbb, 1346px 1909px #bbb,
        772px 270px #bbb, 345px 1190px #bbb, 478px 1433px #bbb, 1479px 25px #bbb,
        1994px 1830px #bbb, 1744px 732px #bbb, 20px 1635px #bbb, 690px 1795px #bbb,
        1594px 569px #bbb, 579px 245px #bbb, 1398px 733px #bbb, 408px 1352px #bbb,
        1774px 120px #bbb, 1152px 1370px #bbb, 1698px 1810px #bbb, 710px 1450px #bbb,
        665px 286px #bbb, 493px 1720px #bbb, 786px 5px #bbb, 637px 1140px #bbb,
        764px 324px #bbb, 927px 310px #bbb, 938px 1424px #bbb, 1884px 744px #bbb,
        913px 462px #bbb, 1831px 1936px #bbb, 1527px 249px #bbb, 36px 1381px #bbb,
        1597px 581px #bbb, 1530px 355px #bbb, 949px 459px #bbb, 799px 828px #bbb,
        242px 1471px #bbb, 654px 797px #bbb, 796px 594px #bbb, 1365px 678px #bbb,
        752px 23px #bbb, 1630px 541px #bbb, 982px 72px #bbb, 1733px 1831px #bbb,
        21px 412px #bbb, 775px 998px #bbb, 335px 1945px #bbb, 264px 583px #bbb,
        158px 1311px #bbb, 528px 164px #bbb, 1978px 574px #bbb, 717px 1203px #bbb,
        734px 1591px #bbb, 1555px 820px #bbb, 16px 1943px #bbb, 1625px 1177px #bbb,
        1236px 690px #bbb, 1585px 1590px #bbb, 1737px 1728px #bbb, 721px 698px #bbb,
        1804px 1186px #bbb, 166px 980px #bbb, 1850px 230px #bbb, 330px 1712px #bbb,
        95px 797px #bbb, 1948px 1078px #bbb, 469px 939px #bbb, 1269px 1899px #bbb,
        955px 1220px #bbb, 1137px 1075px #bbb, 312px 1293px #bbb, 986px 1762px #bbb,
        1103px 1238px #bbb, 428px 1993px #bbb, 355px 570px #bbb, 977px 1836px #bbb,
        1395px 1092px #bbb, 276px 913px #bbb, 1743px 656px #bbb, 773px 502px #bbb,
        1686px 1322px #bbb, 1516px 1945px #bbb, 1334px 501px #bbb, 266px 156px #bbb,
        455px 655px #bbb, 798px 72px #bbb, 1059px 1259px #bbb, 1402px 1687px #bbb,
        236px 1329px #bbb, 1455px 786px #bbb, 146px 1228px #bbb, 1851px 823px #bbb,
        1062px 100px #bbb, 1220px 953px #bbb, 20px 1826px #bbb, 36px 1063px #bbb,
        1525px 338px #bbb, 790px 1521px #bbb, 741px 1099px #bbb, 288px 1489px #bbb,
        700px 1060px #bbb, 390px 1071px #bbb, 411px 1036px #bbb, 1853px 1072px #bbb,
        1446px 1085px #bbb, 1164px 874px #bbb, 924px 925px #bbb, 291px 271px #bbb,
        1257px 1964px #bbb, 1580px 1352px #bbb, 1507px 1216px #bbb, 211px 956px #bbb,
        985px 1195px #bbb, 975px 1640px #bbb, 518px 101px #bbb, 663px 1395px #bbb,
        914px 532px #bbb, 145px 1320px #bbb, 69px 1397px #bbb, 982px 523px #bbb,
        257px 725px #bbb, 1599px 831px #bbb, 1636px 1513px #bbb, 1250px 1158px #bbb,
        1132px 604px #bbb, 183px 102px #bbb, 1057px 318px #bbb, 1247px 1835px #bbb,
        1983px 1110px #bbb, 1077px 1455px #bbb, 921px 1770px #bbb, 806px 1350px #bbb,
        1938px 1992px #bbb, 855px 1260px #bbb, 902px 1345px #bbb, 658px 1908px #bbb,
        1845px 679px #bbb, 712px 1482px #bbb, 595px 950px #bbb, 1784px 1992px #bbb,
        1847px 1785px #bbb, 691px 1004px #bbb, 175px 1179px #bbb, 1666px 1911px #bbb,
        41px 61px #bbb, 971px 1080px #bbb, 1830px 1450px #bbb, 1351px 1518px #bbb,
        1257px 99px #bbb, 1395px 1498px #bbb, 1117px 252px #bbb, 1779px 597px #bbb,
        1346px 729px #bbb, 1108px 1144px #bbb, 402px 691px #bbb, 72px 496px #bbb,
        1673px 1604px #bbb, 1497px 974px #bbb, 1865px 1664px #bbb, 88px 806px #bbb,
        918px 77px #bbb, 244px 1118px #bbb, 256px 1820px #bbb, 1851px 1840px #bbb,
        605px 1851px #bbb, 634px 383px #bbb, 865px 37px #bbb, 943px 1024px #bbb,
        1951px 177px #bbb, 1097px 523px #bbb, 985px 1700px #bbb, 1243px 122px #bbb,
        768px 1070px #bbb, 468px 194px #bbb, 320px 1867px #bbb, 1850px 185px #bbb,
        380px 1616px #bbb, 468px 1294px #bbb, 1122px 1743px #bbb, 884px 299px #bbb,
        1300px 1917px #bbb, 1860px 396px #bbb, 1270px 990px #bbb, 529px 733px #bbb,
        1975px 1347px #bbb, 1885px 685px #bbb, 226px 506px #bbb, 651px 878px #bbb,
        1323px 680px #bbb, 1284px 680px #bbb, 238px 1967px #bbb, 911px 174px #bbb,
        1111px 521px #bbb, 1150px 85px #bbb, 794px 502px #bbb, 484px 1856px #bbb,
        1809px 368px #bbb, 112px 953px #bbb, 590px 1009px #bbb, 1655px 311px #bbb,
        100px 1026px #bbb, 1803px 352px #bbb, 865px 306px #bbb, 1077px 1019px #bbb,
        1335px 872px #bbb, 1647px 1298px #bbb, 1233px 1387px #bbb, 698px 1036px #bbb,
        659px 1860px #bbb, 388px 1412px #bbb, 1212px 458px #bbb, 755px 1468px #bbb,
        696px 1654px #bbb, 1144px 60px #bbb;
    }

    .stars:after {
        content: '';
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: 779px 1331px #bbb, 324px 42px #bbb, 303px 586px #bbb,
        1312px 276px #bbb, 451px 625px #bbb, 521px 1931px #bbb, 1087px 1871px #bbb,
        36px 1546px #bbb, 132px 934px #bbb, 1698px 901px #bbb, 1418px 664px #bbb,
        1448px 1157px #bbb, 1084px 232px #bbb, 347px 1776px #bbb, 1722px 243px #bbb,
        1629px 835px #bbb, 479px 969px #bbb, 1231px 960px #bbb, 586px 384px #bbb,
        164px 527px #bbb, 8px 646px #bbb, 1150px 1126px #bbb, 665px 1357px #bbb,
        1556px 1982px #bbb, 1260px 1961px #bbb, 1675px 1741px #bbb,
        1843px 1514px #bbb, 718px 1628px #bbb, 242px 1343px #bbb, 1497px 1880px #bbb,
        1364px 230px #bbb, 1739px 1302px #bbb, 636px 959px #bbb, 304px 686px #bbb,
        614px 751px #bbb, 1935px 816px #bbb, 1428px 60px #bbb, 355px 335px #bbb,
        1594px 158px #bbb, 90px 60px #bbb, 1553px 162px #bbb, 1239px 1825px #bbb,
        1945px 587px #bbb, 749px 1785px #bbb, 1987px 1172px #bbb, 1301px 1237px #bbb,
        1039px 342px #bbb, 1585px 1481px #bbb, 995px 1048px #bbb, 524px 932px #bbb,
        214px 413px #bbb, 1701px 1300px #bbb, 1037px 1613px #bbb, 1871px 996px #bbb,
        1360px 1635px #bbb, 1110px 1313px #bbb, 412px 1783px #bbb, 1949px 177px #bbb,
        903px 1854px #bbb, 700px 1936px #bbb, 378px 125px #bbb, 308px 834px #bbb,
        1118px 962px #bbb, 1350px 1929px #bbb, 781px 1811px #bbb, 561px 137px #bbb,
        757px 1148px #bbb, 1670px 1979px #bbb, 343px 739px #bbb, 945px 795px #bbb,
        576px 1903px #bbb, 1078px 1436px #bbb, 1583px 450px #bbb, 1366px 474px #bbb,
        297px 1873px #bbb, 192px 162px #bbb, 1624px 1633px #bbb, 59px 453px #bbb,
        82px 1872px #bbb, 1933px 498px #bbb, 1966px 1974px #bbb, 1975px 1688px #bbb,
        779px 314px #bbb, 1858px 1543px #bbb, 73px 1507px #bbb, 1693px 975px #bbb,
        1683px 108px #bbb, 1768px 1654px #bbb, 654px 14px #bbb, 494px 171px #bbb,
        1689px 1895px #bbb, 1660px 263px #bbb, 1031px 903px #bbb, 1203px 1393px #bbb,
        1333px 1421px #bbb, 1113px 41px #bbb, 1206px 1645px #bbb, 1325px 1635px #bbb,
        142px 388px #bbb, 572px 215px #bbb, 1535px 296px #bbb, 1419px 407px #bbb,
        1379px 1003px #bbb, 329px 469px #bbb, 1791px 1652px #bbb, 935px 1802px #bbb,
        1330px 1820px #bbb, 421px 1933px #bbb, 828px 365px #bbb, 275px 316px #bbb,
        707px 960px #bbb, 1605px 1554px #bbb, 625px 58px #bbb, 717px 1697px #bbb,
        1669px 246px #bbb, 1925px 322px #bbb, 1154px 1803px #bbb, 1929px 295px #bbb,
        1248px 240px #bbb, 1045px 1755px #bbb, 166px 942px #bbb, 1888px 1773px #bbb,
        678px 1963px #bbb, 1370px 569px #bbb, 1974px 1400px #bbb, 1786px 460px #bbb,
        51px 307px #bbb, 784px 1400px #bbb, 730px 1258px #bbb, 1712px 393px #bbb,
        416px 170px #bbb, 1797px 1932px #bbb, 572px 219px #bbb, 1557px 1856px #bbb,
        218px 8px #bbb, 348px 1334px #bbb, 469px 413px #bbb, 385px 1738px #bbb,
        1357px 1818px #bbb, 240px 942px #bbb, 248px 1847px #bbb, 1535px 806px #bbb,
        236px 1514px #bbb, 1429px 1556px #bbb, 73px 1633px #bbb, 1398px 1121px #bbb,
        671px 1301px #bbb, 1404px 1663px #bbb, 740px 1018px #bbb, 1600px 377px #bbb,
        785px 514px #bbb, 112px 1084px #bbb, 1915px 1887px #bbb, 1463px 1848px #bbb,
        687px 1115px #bbb, 1268px 1768px #bbb, 1729px 1425px #bbb,
        1284px 1022px #bbb, 801px 974px #bbb, 1975px 1317px #bbb, 1354px 834px #bbb,
        1446px 1484px #bbb, 1283px 1786px #bbb, 11px 523px #bbb, 1842px 236px #bbb,
        1355px 654px #bbb, 429px 7px #bbb, 1033px 1128px #bbb, 157px 297px #bbb,
        545px 635px #bbb, 52px 1080px #bbb, 827px 1520px #bbb, 1121px 490px #bbb,
        9px 309px #bbb, 1744px 1586px #bbb, 1014px 417px #bbb, 1534px 524px #bbb,
        958px 552px #bbb, 1403px 1496px #bbb, 387px 703px #bbb, 1522px 548px #bbb,
        1355px 282px #bbb, 1532px 601px #bbb, 1838px 790px #bbb, 290px 259px #bbb,
        295px 598px #bbb, 1601px 539px #bbb, 1561px 1272px #bbb, 34px 1922px #bbb,
        1024px 543px #bbb, 467px 369px #bbb, 722px 333px #bbb, 1976px 1255px #bbb,
        766px 983px #bbb, 1582px 1285px #bbb, 12px 512px #bbb, 617px 1410px #bbb,
        682px 577px #bbb, 1334px 1438px #bbb, 439px 327px #bbb, 1617px 1661px #bbb,
        673px 129px #bbb, 794px 941px #bbb, 1386px 1902px #bbb, 37px 1353px #bbb,
        1467px 1353px #bbb, 416px 18px #bbb, 187px 344px #bbb, 200px 1898px #bbb,
        1491px 1619px #bbb, 811px 347px #bbb, 924px 1827px #bbb, 945px 217px #bbb,
        1735px 1228px #bbb, 379px 1890px #bbb, 79px 761px #bbb, 825px 1837px #bbb,
        1980px 1558px #bbb, 1308px 1573px #bbb, 1488px 1726px #bbb,
        382px 1208px #bbb, 522px 595px #bbb, 1277px 1898px #bbb, 354px 552px #bbb,
        161px 1784px #bbb, 614px 251px #bbb, 526px 1576px #bbb, 17px 212px #bbb,
        179px 996px #bbb, 467px 1208px #bbb, 1944px 1838px #bbb, 1140px 1093px #bbb,
        858px 1007px #bbb, 200px 1064px #bbb, 423px 1964px #bbb, 1945px 439px #bbb,
        1377px 689px #bbb, 1120px 1437px #bbb, 1876px 668px #bbb, 907px 1324px #bbb,
        343px 1976px #bbb, 1816px 1501px #bbb, 1849px 177px #bbb, 647px 91px #bbb,
        1984px 1012px #bbb, 1336px 1300px #bbb, 128px 648px #bbb, 305px 1060px #bbb,
        1324px 826px #bbb, 1263px 1314px #bbb, 1801px 629px #bbb, 1614px 1555px #bbb,
        1634px 90px #bbb, 1603px 452px #bbb, 891px 1984px #bbb, 1556px 1906px #bbb,
        121px 68px #bbb, 1676px 1714px #bbb, 516px 936px #bbb, 1947px 1492px #bbb,
        1455px 1519px #bbb, 45px 602px #bbb, 205px 1039px #bbb, 793px 172px #bbb,
        1562px 1739px #bbb, 1056px 110px #bbb, 1512px 379px #bbb, 1795px 1621px #bbb,
        1848px 607px #bbb, 262px 1719px #bbb, 477px 991px #bbb, 483px 883px #bbb,
        1239px 1197px #bbb, 1496px 647px #bbb, 1649px 25px #bbb, 1491px 1946px #bbb,
        119px 996px #bbb, 179px 1472px #bbb, 1341px 808px #bbb, 1565px 1700px #bbb,
        407px 1544px #bbb, 1754px 357px #bbb, 1288px 981px #bbb, 902px 1997px #bbb,
        1755px 1668px #bbb, 186px 877px #bbb, 1202px 1882px #bbb, 461px 1213px #bbb,
        1400px 748px #bbb, 1969px 1899px #bbb, 809px 522px #bbb, 514px 1219px #bbb,
        374px 275px #bbb, 938px 1973px #bbb, 357px 552px #bbb, 144px 1722px #bbb,
        1572px 912px #bbb, 402px 1858px #bbb, 1544px 1195px #bbb, 667px 1257px #bbb,
        727px 1496px #bbb, 993px 232px #bbb, 1772px 313px #bbb, 1040px 1590px #bbb,
        1204px 1973px #bbb, 1268px 79px #bbb, 1555px 1048px #bbb, 986px 1707px #bbb,
        978px 1710px #bbb, 713px 360px #bbb, 407px 863px #bbb, 461px 736px #bbb,
        284px 1608px #bbb, 103px 430px #bbb, 1283px 1319px #bbb, 977px 1186px #bbb,
        1966px 1516px #bbb, 1287px 1129px #bbb, 70px 1098px #bbb, 1189px 889px #bbb,
        1126px 1734px #bbb, 309px 1292px #bbb, 879px 764px #bbb, 65px 473px #bbb,
        1003px 1959px #bbb, 658px 791px #bbb, 402px 1576px #bbb, 35px 622px #bbb,
        529px 1589px #bbb, 164px 666px #bbb, 1876px 1290px #bbb, 1541px 526px #bbb,
        270px 1297px #bbb, 440px 865px #bbb, 1500px 802px #bbb, 182px 1754px #bbb,
        1264px 892px #bbb, 272px 1249px #bbb, 1289px 1535px #bbb, 190px 1646px #bbb,
        955px 242px #bbb, 1456px 1597px #bbb, 1727px 1983px #bbb, 635px 801px #bbb,
        226px 455px #bbb, 1396px 1710px #bbb, 849px 1863px #bbb, 237px 1264px #bbb,
        839px 140px #bbb, 1122px 735px #bbb, 1280px 15px #bbb, 1318px 242px #bbb,
        1819px 1148px #bbb, 333px 1392px #bbb, 1949px 553px #bbb, 1878px 1332px #bbb,
        467px 548px #bbb, 1812px 1082px #bbb, 1067px 193px #bbb, 243px 156px #bbb,
        483px 1616px #bbb, 1714px 933px #bbb, 759px 1800px #bbb, 1822px 995px #bbb,
        1877px 572px #bbb, 581px 1084px #bbb, 107px 732px #bbb, 642px 1837px #bbb,
        166px 1493px #bbb, 1555px 198px #bbb, 819px 307px #bbb, 947px 345px #bbb,
        827px 224px #bbb, 927px 1394px #bbb, 540px 467px #bbb, 1093px 405px #bbb,
        1140px 927px #bbb, 130px 529px #bbb, 33px 1980px #bbb, 1147px 1663px #bbb,
        1616px 1436px #bbb, 528px 710px #bbb, 798px 1100px #bbb, 505px 1480px #bbb,
        899px 641px #bbb, 1909px 1949px #bbb, 1311px 964px #bbb, 979px 1301px #bbb,
        1393px 969px #bbb, 1793px 1886px #bbb, 292px 357px #bbb, 1196px 1718px #bbb,
        1290px 1994px #bbb, 537px 1973px #bbb, 1181px 1674px #bbb,
        1740px 1566px #bbb, 1307px 265px #bbb, 922px 522px #bbb, 1892px 472px #bbb,
        384px 1746px #bbb, 392px 1098px #bbb, 647px 548px #bbb, 390px 1498px #bbb,
        1246px 138px #bbb, 730px 876px #bbb, 192px 1472px #bbb, 1790px 1789px #bbb,
        928px 311px #bbb, 1253px 1647px #bbb, 747px 1921px #bbb, 1561px 1025px #bbb,
        1533px 1292px #bbb, 1985px 195px #bbb, 728px 729px #bbb, 1712px 1936px #bbb,
        512px 1717px #bbb, 1528px 483px #bbb, 313px 1642px #bbb, 281px 1849px #bbb,
        1212px 799px #bbb, 435px 1191px #bbb, 1422px 611px #bbb, 1718px 1964px #bbb,
        411px 944px #bbb, 210px 636px #bbb, 1502px 1295px #bbb, 1434px 349px #bbb,
        769px 60px #bbb, 747px 1053px #bbb, 789px 504px #bbb, 1436px 1264px #bbb,
        1893px 1225px #bbb, 1394px 1788px #bbb, 1108px 1317px #bbb,
        1673px 1395px #bbb, 854px 1010px #bbb, 1705px 80px #bbb, 1858px 148px #bbb,
        1729px 344px #bbb, 1388px 664px #bbb, 895px 406px #bbb, 1479px 157px #bbb,
        1441px 1157px #bbb, 552px 1900px #bbb, 516px 364px #bbb, 1647px 189px #bbb,
        1427px 1071px #bbb, 785px 729px #bbb, 1080px 1710px #bbb, 504px 204px #bbb,
        1177px 1622px #bbb, 657px 34px #bbb, 1296px 1099px #bbb, 248px 180px #bbb,
        1212px 1568px #bbb, 667px 1562px #bbb, 695px 841px #bbb, 1608px 1247px #bbb,
        751px 882px #bbb, 87px 167px #bbb, 607px 1368px #bbb, 1363px 1203px #bbb,
        1836px 317px #bbb, 1668px 1703px #bbb, 830px 1154px #bbb, 1721px 1398px #bbb,
        1601px 1280px #bbb, 976px 874px #bbb, 1743px 254px #bbb, 1020px 1815px #bbb,
        1670px 1766px #bbb, 1890px 735px #bbb, 1379px 136px #bbb, 1864px 695px #bbb,
        206px 965px #bbb, 1404px 1932px #bbb, 1923px 1360px #bbb, 247px 682px #bbb,
        519px 1708px #bbb, 645px 750px #bbb, 1164px 1204px #bbb, 834px 323px #bbb,
        172px 1350px #bbb, 213px 972px #bbb, 1837px 190px #bbb, 285px 1806px #bbb,
        1047px 1299px #bbb, 1548px 825px #bbb, 1730px 324px #bbb, 1346px 1909px #bbb,
        772px 270px #bbb, 345px 1190px #bbb, 478px 1433px #bbb, 1479px 25px #bbb,
        1994px 1830px #bbb, 1744px 732px #bbb, 20px 1635px #bbb, 690px 1795px #bbb,
        1594px 569px #bbb, 579px 245px #bbb, 1398px 733px #bbb, 408px 1352px #bbb,
        1774px 120px #bbb, 1152px 1370px #bbb, 1698px 1810px #bbb, 710px 1450px #bbb,
        665px 286px #bbb, 493px 1720px #bbb, 786px 5px #bbb, 637px 1140px #bbb,
        764px 324px #bbb, 927px 310px #bbb, 938px 1424px #bbb, 1884px 744px #bbb,
        913px 462px #bbb, 1831px 1936px #bbb, 1527px 249px #bbb, 36px 1381px #bbb,
        1597px 581px #bbb, 1530px 355px #bbb, 949px 459px #bbb, 799px 828px #bbb,
        242px 1471px #bbb, 654px 797px #bbb, 796px 594px #bbb, 1365px 678px #bbb,
        752px 23px #bbb, 1630px 541px #bbb, 982px 72px #bbb, 1733px 1831px #bbb,
        21px 412px #bbb, 775px 998px #bbb, 335px 1945px #bbb, 264px 583px #bbb,
        158px 1311px #bbb, 528px 164px #bbb, 1978px 574px #bbb, 717px 1203px #bbb,
        734px 1591px #bbb, 1555px 820px #bbb, 16px 1943px #bbb, 1625px 1177px #bbb,
        1236px 690px #bbb, 1585px 1590px #bbb, 1737px 1728px #bbb, 721px 698px #bbb,
        1804px 1186px #bbb, 166px 980px #bbb, 1850px 230px #bbb, 330px 1712px #bbb,
        95px 797px #bbb, 1948px 1078px #bbb, 469px 939px #bbb, 1269px 1899px #bbb,
        955px 1220px #bbb, 1137px 1075px #bbb, 312px 1293px #bbb, 986px 1762px #bbb,
        1103px 1238px #bbb, 428px 1993px #bbb, 355px 570px #bbb, 977px 1836px #bbb,
        1395px 1092px #bbb, 276px 913px #bbb, 1743px 656px #bbb, 773px 502px #bbb,
        1686px 1322px #bbb, 1516px 1945px #bbb, 1334px 501px #bbb, 266px 156px #bbb,
        455px 655px #bbb, 798px 72px #bbb, 1059px 1259px #bbb, 1402px 1687px #bbb,
        236px 1329px #bbb, 1455px 786px #bbb, 146px 1228px #bbb, 1851px 823px #bbb,
        1062px 100px #bbb, 1220px 953px #bbb, 20px 1826px #bbb, 36px 1063px #bbb,
        1525px 338px #bbb, 790px 1521px #bbb, 741px 1099px #bbb, 288px 1489px #bbb,
        700px 1060px #bbb, 390px 1071px #bbb, 411px 1036px #bbb, 1853px 1072px #bbb,
        1446px 1085px #bbb, 1164px 874px #bbb, 924px 925px #bbb, 291px 271px #bbb,
        1257px 1964px #bbb, 1580px 1352px #bbb, 1507px 1216px #bbb, 211px 956px #bbb,
        985px 1195px #bbb, 975px 1640px #bbb, 518px 101px #bbb, 663px 1395px #bbb,
        914px 532px #bbb, 145px 1320px #bbb, 69px 1397px #bbb, 982px 523px #bbb,
        257px 725px #bbb, 1599px 831px #bbb, 1636px 1513px #bbb, 1250px 1158px #bbb,
        1132px 604px #bbb, 183px 102px #bbb, 1057px 318px #bbb, 1247px 1835px #bbb,
        1983px 1110px #bbb, 1077px 1455px #bbb, 921px 1770px #bbb, 806px 1350px #bbb,
        1938px 1992px #bbb, 855px 1260px #bbb, 902px 1345px #bbb, 658px 1908px #bbb,
        1845px 679px #bbb, 712px 1482px #bbb, 595px 950px #bbb, 1784px 1992px #bbb,
        1847px 1785px #bbb, 691px 1004px #bbb, 175px 1179px #bbb, 1666px 1911px #bbb,
        41px 61px #bbb, 971px 1080px #bbb, 1830px 1450px #bbb, 1351px 1518px #bbb,
        1257px 99px #bbb, 1395px 1498px #bbb, 1117px 252px #bbb, 1779px 597px #bbb,
        1346px 729px #bbb, 1108px 1144px #bbb, 402px 691px #bbb, 72px 496px #bbb,
        1673px 1604px #bbb, 1497px 974px #bbb, 1865px 1664px #bbb, 88px 806px #bbb,
        918px 77px #bbb, 244px 1118px #bbb, 256px 1820px #bbb, 1851px 1840px #bbb,
        605px 1851px #bbb, 634px 383px #bbb, 865px 37px #bbb, 943px 1024px #bbb,
        1951px 177px #bbb, 1097px 523px #bbb, 985px 1700px #bbb, 1243px 122px #bbb,
        768px 1070px #bbb, 468px 194px #bbb, 320px 1867px #bbb, 1850px 185px #bbb,
        380px 1616px #bbb, 468px 1294px #bbb, 1122px 1743px #bbb, 884px 299px #bbb,
        1300px 1917px #bbb, 1860px 396px #bbb, 1270px 990px #bbb, 529px 733px #bbb,
        1975px 1347px #bbb, 1885px 685px #bbb, 226px 506px #bbb, 651px 878px #bbb,
        1323px 680px #bbb, 1284px 680px #bbb, 238px 1967px #bbb, 911px 174px #bbb,
        1111px 521px #bbb, 1150px 85px #bbb, 794px 502px #bbb, 484px 1856px #bbb,
        1809px 368px #bbb, 112px 953px #bbb, 590px 1009px #bbb, 1655px 311px #bbb,
        100px 1026px #bbb, 1803px 352px #bbb, 865px 306px #bbb, 1077px 1019px #bbb,
        1335px 872px #bbb, 1647px 1298px #bbb, 1233px 1387px #bbb, 698px 1036px #bbb,
        659px 1860px #bbb, 388px 1412px #bbb, 1212px 458px #bbb, 755px 1468px #bbb,
        696px 1654px #bbb, 1144px 60px #bbb;
    }

    .stars2 {
        width: 2px;
        height: 2px;
        background: transparent;
        overflow: hidden;
        z-index: 0;
        animation: animStar 350s linear infinite;
        box-shadow: 1448px 320px #bbb, 1775px 1663px #bbb, 332px 1364px #bbb,
        878px 340px #bbb, 569px 1832px #bbb, 1422px 1684px #bbb, 1946px 1907px #bbb,
        121px 979px #bbb, 1044px 1069px #bbb, 463px 381px #bbb, 423px 112px #bbb,
        523px 1179px #bbb, 779px 654px #bbb, 1398px 694px #bbb, 1085px 1464px #bbb,
        1599px 1869px #bbb, 801px 1882px #bbb, 779px 1231px #bbb, 552px 932px #bbb,
        1057px 1196px #bbb, 282px 1280px #bbb, 496px 1986px #bbb, 1833px 1120px #bbb,
        1802px 1293px #bbb, 6px 1696px #bbb, 412px 1902px #bbb, 605px 438px #bbb,
        24px 1212px #bbb, 234px 1320px #bbb, 544px 344px #bbb, 1107px 170px #bbb,
        1603px 196px #bbb, 905px 648px #bbb, 68px 1458px #bbb, 649px 1969px #bbb,
        744px 675px #bbb, 1127px 478px #bbb, 714px 1814px #bbb, 1486px 526px #bbb,
        270px 1636px #bbb, 1931px 149px #bbb, 1807px 378px #bbb, 8px 390px #bbb,
        1415px 699px #bbb, 1473px 1211px #bbb, 1590px 141px #bbb, 270px 1705px #bbb,
        69px 1423px #bbb, 1108px 1053px #bbb, 1946px 128px #bbb, 371px 371px #bbb,
        1490px 220px #bbb, 357px 1885px #bbb, 363px 363px #bbb, 1896px 1256px #bbb,
        1979px 1050px #bbb, 947px 1342px #bbb, 1754px 242px #bbb, 514px 974px #bbb,
        65px 1477px #bbb, 1840px 547px #bbb, 950px 695px #bbb, 459px 1150px #bbb,
        1124px 1502px #bbb, 481px 940px #bbb, 680px 839px #bbb, 797px 1169px #bbb,
        1977px 1491px #bbb, 734px 1724px #bbb, 210px 298px #bbb, 816px 628px #bbb,
        686px 770px #bbb, 1721px 267px #bbb, 1663px 511px #bbb, 1481px 1141px #bbb,
        582px 248px #bbb, 1308px 953px #bbb, 628px 657px #bbb, 897px 1535px #bbb,
        270px 931px #bbb, 791px 467px #bbb, 1336px 1732px #bbb, 1013px 1653px #bbb,
        1911px 956px #bbb, 587px 816px #bbb, 83px 456px #bbb, 930px 1478px #bbb,
        1587px 1694px #bbb, 614px 1200px #bbb, 302px 1782px #bbb, 1711px 1432px #bbb,
        443px 904px #bbb, 1666px 714px #bbb, 1588px 1167px #bbb, 273px 1075px #bbb,
        1679px 461px #bbb, 721px 664px #bbb, 1202px 10px #bbb, 166px 1126px #bbb,
        331px 1628px #bbb, 430px 1565px #bbb, 1585px 509px #bbb, 640px 38px #bbb,
        822px 837px #bbb, 1760px 1664px #bbb, 1122px 1458px #bbb, 398px 131px #bbb,
        689px 285px #bbb, 460px 652px #bbb, 1627px 365px #bbb, 348px 1648px #bbb,
        819px 1946px #bbb, 981px 1917px #bbb, 323px 76px #bbb, 979px 684px #bbb,
        887px 536px #bbb, 1348px 1596px #bbb, 1055px 666px #bbb, 1402px 1797px #bbb,
        1300px 1055px #bbb, 937px 238px #bbb, 1474px 1815px #bbb, 1144px 1710px #bbb,
        1629px 1087px #bbb, 911px 919px #bbb, 771px 819px #bbb, 403px 720px #bbb,
        163px 736px #bbb, 1062px 238px #bbb, 1774px 818px #bbb, 1874px 1178px #bbb,
        1177px 699px #bbb, 1244px 1244px #bbb, 1371px 58px #bbb, 564px 1515px #bbb,
        1824px 487px #bbb, 929px 702px #bbb, 394px 1348px #bbb, 1161px 641px #bbb,
        219px 1841px #bbb, 358px 941px #bbb, 140px 1759px #bbb, 1019px 1345px #bbb,
        274px 436px #bbb, 1433px 1605px #bbb, 1798px 1426px #bbb, 294px 1848px #bbb,
        1681px 1877px #bbb, 1344px 1824px #bbb, 1439px 1632px #bbb,
        161px 1012px #bbb, 1308px 588px #bbb, 1789px 582px #bbb, 721px 1910px #bbb,
        318px 218px #bbb, 607px 319px #bbb, 495px 535px #bbb, 1552px 1575px #bbb,
        1562px 67px #bbb, 403px 926px #bbb, 1096px 1800px #bbb, 1814px 1709px #bbb,
        1882px 1831px #bbb, 533px 46px #bbb, 823px 969px #bbb, 530px 165px #bbb,
        1030px 352px #bbb, 1681px 313px #bbb, 338px 115px #bbb, 1607px 211px #bbb,
        1718px 1184px #bbb, 1589px 659px #bbb, 278px 355px #bbb, 464px 1464px #bbb,
        1165px 277px #bbb, 950px 694px #bbb, 1746px 293px #bbb, 793px 911px #bbb,
        528px 773px #bbb, 1883px 1694px #bbb, 748px 182px #bbb, 1924px 1531px #bbb,
        100px 636px #bbb, 1473px 1445px #bbb, 1264px 1244px #bbb, 850px 1377px #bbb,
        987px 1976px #bbb, 933px 1761px #bbb, 922px 1270px #bbb, 500px 396px #bbb,
        1324px 8px #bbb, 1967px 1814px #bbb, 1072px 1401px #bbb, 961px 37px #bbb,
        156px 81px #bbb, 1915px 502px #bbb, 1076px 1846px #bbb, 152px 1669px #bbb,
        986px 1529px #bbb, 1667px 1137px #bbb;
    }

    .stars2:after {
        content: '';
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: 1448px 320px #bbb, 1775px 1663px #bbb, 332px 1364px #bbb,
        878px 340px #bbb, 569px 1832px #bbb, 1422px 1684px #bbb, 1946px 1907px #bbb,
        121px 979px #bbb, 1044px 1069px #bbb, 463px 381px #bbb, 423px 112px #bbb,
        523px 1179px #bbb, 779px 654px #bbb, 1398px 694px #bbb, 1085px 1464px #bbb,
        1599px 1869px #bbb, 801px 1882px #bbb, 779px 1231px #bbb, 552px 932px #bbb,
        1057px 1196px #bbb, 282px 1280px #bbb, 496px 1986px #bbb, 1833px 1120px #bbb,
        1802px 1293px #bbb, 6px 1696px #bbb, 412px 1902px #bbb, 605px 438px #bbb,
        24px 1212px #bbb, 234px 1320px #bbb, 544px 344px #bbb, 1107px 170px #bbb,
        1603px 196px #bbb, 905px 648px #bbb, 68px 1458px #bbb, 649px 1969px #bbb,
        744px 675px #bbb, 1127px 478px #bbb, 714px 1814px #bbb, 1486px 526px #bbb,
        270px 1636px #bbb, 1931px 149px #bbb, 1807px 378px #bbb, 8px 390px #bbb,
        1415px 699px #bbb, 1473px 1211px #bbb, 1590px 141px #bbb, 270px 1705px #bbb,
        69px 1423px #bbb, 1108px 1053px #bbb, 1946px 128px #bbb, 371px 371px #bbb,
        1490px 220px #bbb, 357px 1885px #bbb, 363px 363px #bbb, 1896px 1256px #bbb,
        1979px 1050px #bbb, 947px 1342px #bbb, 1754px 242px #bbb, 514px 974px #bbb,
        65px 1477px #bbb, 1840px 547px #bbb, 950px 695px #bbb, 459px 1150px #bbb,
        1124px 1502px #bbb, 481px 940px #bbb, 680px 839px #bbb, 797px 1169px #bbb,
        1977px 1491px #bbb, 734px 1724px #bbb, 210px 298px #bbb, 816px 628px #bbb,
        686px 770px #bbb, 1721px 267px #bbb, 1663px 511px #bbb, 1481px 1141px #bbb,
        582px 248px #bbb, 1308px 953px #bbb, 628px 657px #bbb, 897px 1535px #bbb,
        270px 931px #bbb, 791px 467px #bbb, 1336px 1732px #bbb, 1013px 1653px #bbb,
        1911px 956px #bbb, 587px 816px #bbb, 83px 456px #bbb, 930px 1478px #bbb,
        1587px 1694px #bbb, 614px 1200px #bbb, 302px 1782px #bbb, 1711px 1432px #bbb,
        443px 904px #bbb, 1666px 714px #bbb, 1588px 1167px #bbb, 273px 1075px #bbb,
        1679px 461px #bbb, 721px 664px #bbb, 1202px 10px #bbb, 166px 1126px #bbb,
        331px 1628px #bbb, 430px 1565px #bbb, 1585px 509px #bbb, 640px 38px #bbb,
        822px 837px #bbb, 1760px 1664px #bbb, 1122px 1458px #bbb, 398px 131px #bbb,
        689px 285px #bbb, 460px 652px #bbb, 1627px 365px #bbb, 348px 1648px #bbb,
        819px 1946px #bbb, 981px 1917px #bbb, 323px 76px #bbb, 979px 684px #bbb,
        887px 536px #bbb, 1348px 1596px #bbb, 1055px 666px #bbb, 1402px 1797px #bbb,
        1300px 1055px #bbb, 937px 238px #bbb, 1474px 1815px #bbb, 1144px 1710px #bbb,
        1629px 1087px #bbb, 911px 919px #bbb, 771px 819px #bbb, 403px 720px #bbb,
        163px 736px #bbb, 1062px 238px #bbb, 1774px 818px #bbb, 1874px 1178px #bbb,
        1177px 699px #bbb, 1244px 1244px #bbb, 1371px 58px #bbb, 564px 1515px #bbb,
        1824px 487px #bbb, 929px 702px #bbb, 394px 1348px #bbb, 1161px 641px #bbb,
        219px 1841px #bbb, 358px 941px #bbb, 140px 1759px #bbb, 1019px 1345px #bbb,
        274px 436px #bbb, 1433px 1605px #bbb, 1798px 1426px #bbb, 294px 1848px #bbb,
        1681px 1877px #bbb, 1344px 1824px #bbb, 1439px 1632px #bbb,
        161px 1012px #bbb, 1308px 588px #bbb, 1789px 582px #bbb, 721px 1910px #bbb,
        318px 218px #bbb, 607px 319px #bbb, 495px 535px #bbb, 1552px 1575px #bbb,
        1562px 67px #bbb, 403px 926px #bbb, 1096px 1800px #bbb, 1814px 1709px #bbb,
        1882px 1831px #bbb, 533px 46px #bbb, 823px 969px #bbb, 530px 165px #bbb,
        1030px 352px #bbb, 1681px 1313px #bbb, 338px 115px #bbb, 1607px 211px #bbb,
        1718px 1184px #bbb, 1589px 659px #bbb, 278px 355px #bbb, 464px 1464px #bbb,
        1165px 277px #bbb, 950px 694px #bbb, 1746px 293px #bbb, 793px 911px #bbb,
        528px 773px #bbb, 1883px 1694px #bbb, 748px 182px #bbb, 1924px 1531px #bbb,
        100px 636px #bbb, 1473px 1445px #bbb, 1264px 1244px #bbb, 850px 1377px #bbb,
        987px 1976px #bbb, 933px 1761px #bbb, 922px 1270px #bbb, 500px 396px #bbb,
        1324px 8px #bbb, 1967px 1814px #bbb, 1072px 1401px #bbb, 961px 37px #bbb,
        156px 81px #bbb, 1915px 502px #bbb, 1076px 1846px #bbb, 152px 2669px #bbb,
        986px 1529px #bbb, 1667px 1137px #bbb, 1967px 2814px #bbb, 1072px 1901px #bbb, 961px 2137px #bbb,
        156px 81px #bbb, 1915px 502px #bbb, 1076px 1846px #bbb, 152px 2669px #bbb,
        986px 2229px #bbb, 1667px 2737px #bbb, 1967px 2314px #bbb, 1072px 3401px #bbb, 961px 2537px #bbb,
        156px 3581px #bbb, 1915px 32502px #bbb, 1076px 3846px #bbb, 152px 2969px #bbb,
        986px 2529px #bbb, 1667px 2837px #bbb;
    }

    .starOffset {
        top: 4000px;
        overflow: hidden;
    }

    .starOffset2 {
        top: 2000px;
        overflow: hidden;
    }

    .starOffset3 {
        top: 1970px;
        overflow: hidden;
    }

    @keyframes animStar {
        from {
            transform: translateY(0px);
        }
        to {
            transform: translateY(-3000px);
        }
    }
}